body{
    margin:0;
    background-color: rgb(250, 244, 236,0.8);
    font-family: 'Century Gothic', sans-serif;
}

html,body{
    min-height: 100vh;
    max-width: 100vw;
    width:100vw;
}

button.icon-button{
    cursor: pointer;
    border:none;
    outline:none;
    background:none;
}

.container{
    margin-top:1rem;
    border-radius:20px;
    background-color: rgb(241, 222, 196);
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

section{
    flex-direction: column;
    width:100vw;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5769C4;
}

.wip-title{
    font-size: 3rem;
    color: #fff;
    margin-bottom: 1rem;
    font-weight: bold;
}

.wip-title>span{
    color:#E5B942;
}