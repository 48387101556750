.table-container {
    width: 100%;
    overflow-x: auto; /* Permet le défilement horizontal */
    height: 100%;
    padding:1rem 0 2rem 0;
}

.table-game {
    width: 100%;
    overflow: scroll;
}

.table-game thead{
    height:70px;    
}

.table-game td {
    min-width: 75px;
    border: 1px solid black;
    text-align: center;
    font-size:1.5rem;
}
td.index-donne{
    border:none;
}

.table-game tr{
    text-align: center;
}

.score.negative{
    background-color: red;
}

.score.positive{
    background-color: green;
}

.score.highest{
    background-color: gold;
}

.total-row td {
  padding-bottom: 10px; /* Espace en bas de la ligne totale */
}

.table-game tbody tr:not(.total-row) {
  margin-top: 10px; /* Espace au-dessus des lignes suivantes */
}
