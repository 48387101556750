form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

form div {
  margin-bottom: 10px;
}

form label {
  margin-right: 10px;
}

form input {
  width: 50px;
}

.input-bloc{
  display:flex;
  flex-direction:column;
}