.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    color: white;
    height:70px
}

.navbar .back-button {
    flex: 1;
    text-align: left;
}

.navbar-title {
    flex: 2;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    span{
        font-size: 2rem;
    }
}

.changeInput{
    display:none;
}

.navbar .options-button {
    flex: 1;
    text-align: right;
}