/* styles.css */
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 50%;
    left:50%;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 48px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2%;
  }
  
  .floating-button:hover {
    background-color: #0056b3;
  }

  .button-content{
    display:flex;
    justify-content: center;
    align-items:center;
  }